import './src/global.css'
import { FAQ_URL } from './src/components/constants'

const EXCLUDED = [FAQ_URL]

export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname: prevPathname } = prevRouterProps?.location ?? {}
  const { pathname } = location

  const isExcluded = EXCLUDED.some(
    (item) => pathname.includes(item) && prevPathname?.includes(item)
  )

  if (isExcluded) {
    return false
  }

  const currentPosition = getSavedScrollPosition(location)

  setTimeout(() => window.scrollTo(...(currentPosition ?? [0, 0])), 0)

  return true
}
