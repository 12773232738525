import click_icon from '../images/aiAppsLibrary/click_icon.png'
import dots_icon from '../images/aiAppsLibrary/dots_icon.png'
import request_icon from '../images/aiAppsLibrary/request_icon.png'
import report_icon from '../images/aiAppsLibrary/report_icon.png'
import multi_icon from '../images/aiAppsLibrary/multi_icon.png'
import low_density from '../images/aiAppsLibrary/low_density.svg'
import transition_slide from '../images/aiAppsLibrary/transition_slide.svg'
import hematology from '../images/aiAppsLibrary/hematology.svg'
import manga from '../images/aiAppsLibrary/manga.svg'
import dirty_lens from '../images/aiAppsLibrary/dirty_lens.svg'
import sound_detection_glass_break from '../images/aiAppsLibrary/sound_detection_glass_break.svg'
import vibration from '../images/aiAppsLibrary/vibration.svg'

export const ROUTINE_HISTOLOGY = 'Routine Histology'

export const IMMUNOSTAINING = 'Immunostaining'
export const TELEPATHOLOGY = 'TELEPATHOLOGY'
export const QUANTITATIVE_IMAGE_ANALYSIS = 'Image Analysis'
export const SLIDE_SCANNING = 'SLIDE SCANNING'
export const SLIDE_ARCHIVING = 'Slide Archiving'

export const ROUTINE_HISTOLOGY_ANCHOR = '/#routine_histology'
export const IMMUNOSTAINING_ANCHOR = '/#immunostaining'
export const TELEPATHOLOGY_URL = '/telepathology'
export const IMMUNOSTAINING_IHC_URL = '/immunostaining/ihc'
export const IMMUNOSTAINING_IF_URL = '/immunostaining/if'
export const IMMUNOSTAINING_ISH_URL = '/immunostaining/ish'

export const FAQ_URL = '/faq'

export const WHOLE_SLIDE_IMAGING_ANCHOR = '/#whole_slide_imaging'
export const TELEPATHOLOGY_CONSULTATION_ANCHOR = '/#telepathology'

export const QUANTITATIVE_IMAGE_ANALYSIS_ANCHOR =
  '/#quantitative_image_analysis'
export const LONG_TERM_DATA_MANAGEMENT_ANCHOR = '/#long_term_data_management'
export const SLIDE_SCANNING_ANCHOR = '/#slide_scanning'
export const SLIDE_ARCHIVING_ANCHOR = '/#slide_archiving'

export const ROUTINE_HISTOLOGY_ID = 'routine_histology'
export const IMMUNOSTAINING_ID = 'immunostaining'
export const TELEPATHOLOGY_ID = 'telepathology'
export const QUANTITATIVE_IMAGE_ANALYSIS_ID = 'quantitative_image_analysis'
export const SLIDE_SCANNING_ID = 'slide_scanning'

export const SLIDE_SCANNING_URL = '/whole_slide_scanning'

export const PATHOLOGY_CONSULTATION = 'Path Consult'
export const PATHOLOGY_CONSULTATION_ANCHOR = '/#pathology_consultation'

//export const WHOLE_SLIDE_IMAGING_ID = 'whole_slide_imaging'
//export const PATHOLOGY_CONSULTATION_ID = 'online_pathology_consultation'
//export const LONG_TERM_DATA_MANAGEMENT_ID = 'long_term_data_management'

export const EXECUTIVE_TEAM_NAME = 'executive'
export const HISTOWIZ_STAFF_TEAM_NAME = 'histowiz_staff'
export const EXECUTIVE_PATHOLOGISTS_TEAM_NAME = 'executive_pathologists'
export const PATHOLOGISTS_TEAM_NAME = 'pathologists'
export const ADVISORS_TEAM_NAME = 'advisors'

export const OSD_VIEWER_ID = 'openseadragon'
export const OSD_VIEWER_HOMEPAGE_TILE_SOURCES = [
  //'https://s3.amazonaws.com/staging-react-ef58dd4e/dzi/65544/viewer.dzi',
  'https://s3.amazonaws.com/staging-react-ef58dd4e/dzi/10265/viewer.dzi',
  'https://s3.amazonaws.com/staging-react-ef58dd4e/dzi/23255/viewer.dzi',
  //'https://s3.amazonaws.com/staging-react-ef58dd4e/dzi/25562/viewer.dzi',
  'https://s3.amazonaws.com/staging-react-ef58dd4e/dzi/29630/viewer.dzi',
  'https://s3.amazonaws.com/staging-react-ef58dd4e/dzi/36541/viewer.dzi',
  //'https://s3.amazonaws.com/staging-react-ef58dd4e/dzi/36548/viewer.dzi',
  //'https://s3.amazonaws.com/staging-react-ef58dd4e/dzi/43422/viewer.dzi',
]

export const OSD_VIEWER_IHC_TILE_SOURCES = [
  'https://storage.googleapis.com/production-histowiz-public/slides/svs/converted/255739/viewer.dzi',
]

export const OSD_VIEWER_BACKGROUND_TILE_SOURCES = [
  'https://api.histowiz.com/image_server/slide/5ed45b21-353a-49f6-b6f1-d6c0c8fa69a5/viewer.dzi',
]

export const OSD_VIEWER_PATHOLOGY_IHC_SOURCE = [
  'https://api.histowiz.com/image_server/slide/61053d97-b098-40a2-bf29-990db258f03d/viewer.dzi',
]

export const OSD_VIEWER_PATHOLOGY_HE_SOURCE = [
  'https://api.histowiz.com/image_server/slide/5ed45b21-353a-49f6-b6f1-d6c0c8fa69a5/viewer.dzi',
]

export const GLP_PC_INQUIRY_FORM_LINK =
  'https://features.histowiz.com/glp-pathology-consultant-inquiry-form'
export const SLIDE_ARCHIVAL_INQUIRY_FORM_LINK =
  'https://features.histowiz.com/slide-archival-inquiry-form'
export const GLP_COMPLIANT_INQUIRY_FORM_LINK =
  'https://features.histowiz.com/glp-pathology-consultant-inquiry-form'
export const AIRA_MATRIX_NEWS_HREF =
  'https://www.einnews.com/pr_news/709334125/histowiz-and-aira-matrix-collaborate-to-offer-advanced-ai-models-on-the-pathologymap-platform'
export const AIOSYN_NEWS_HREF =
  ' https://world.einnews.com/pr_news/713470814/aiosyn-and-histowiz-partner-to-improve-ki[…]research-through-ai-powered-preclinical-and-research-studies'

export const REACT_API_URL = 'https://api.histowiz.com'

export const SCHEMA_FEATURES = [
  {
    text: 'One-Click AI Analysis',
    icon: click_icon,
  },
  {
    text: 'Reporting and Quantification',
    icon: report_icon,
  },
  {
    text: 'Multi-Slide Assessment',
    icon: multi_icon,
  },
  {
    text: 'Collaboration & Sharing',
    icon: dots_icon,
  },
  {
    text: 'Request Evaluation',
    icon: request_icon,
  },
]

export const AUTOMATED_QC_LEFT_SECTION_ITEMS = [
  {
    text: 'Out of Focus',
    icon: low_density,
  },
  {
    text: 'Tissue Separation',
    icon: transition_slide,
  },
  {
    text: 'Coverslip / Air bubbles',
    icon: hematology,
  },
  {
    text: 'Folds',
    icon: manga,
  },
]

export const AUTOMATED_QC_RIGHT_SECTION_ITEMS = [
  {
    text: 'Dirty Slide',
    icon: dirty_lens,
  },
  {
    text: 'Tissue Cracking',
    icon: sound_detection_glass_break,
  },
  {
    text: 'Microvibrations',
    icon: vibration,
  },
]

export const VIEWERS_PREVIEW_INFO_ITEMS = [
  {
    title: 'AI-Driven Quality Control',
    text: 'Utilize advanced AI technology to ensure the highest  data quality standards in histopathology.',
  },
  {
    title: 'Automatic Anomaly Detection',
    text: 'Automatically detect and flag anomalies in slide quality, saving time and enhancing accuracy.',
  },
  {
    title: 'Efficiency Boost',
    text: 'Streamline your workflows, reduce lab workload, and focus on critical analysis.',
  },
  {
    title: 'Reliable Results',
    text: 'Guarantee consistent and reliable results with every use.',
  },
]

export const AIOSYN_PARTNER_KEYS = [
  'Enhance your kidney disease research with Aiosyn’s AI-powered solutions.',
  'Specifically designed for preclinical and research studies.',
  'Offers robust tools for analyzing renal tissue samples.',
  'Enables more precise and faster identification of pathological changes.',
  'Improves the understanding and treatment of kidney diseases.',
  'Brings state-of-the-art AI algorithms to your research.',
]

export const AIRA_PARTNER_KEYS = [
  'Discover the power of advanced AI models with AIRA Matrix.',
  'Offers sophisticated image analysis capabilities for automated scoring, quantification, and histological feature recognition.',
  'Enhances your ability to derive actionable insights from complex data.',
  'Facilitates faster and more accurate diagnoses.',
  'Ideal for pre-clinical and clinical research',
]
